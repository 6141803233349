/**
 * Slider
 */
import Swiper from 'swiper';
import { 
    Parallax, 
    Navigation, 
    Pagination, 
    EffectCreative, 
    EffectCoverflow, 
    Autoplay} from 'swiper/modules';


export const Slider = function() {
    const selectors = {
        'slider': '.js-slider',
    };

    const options = {
        slideHero: {
            modules: [Pagination, Navigation, Parallax, Autoplay],
            parallax: true,
            touchRatio: 0.8,

            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                dynamicBullets: false,
                clickable: true,
            },

            spaceBetween: 30,
            slidesPerView: 'auto',
            grabCursor: true,

            // slideToClickedSlide: true,,
            touchMove: false,
            loop: true,

            speed: 1200,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        },
        slideLogos: {
            modules: [Autoplay],
            spaceBetween: 40,
            grabCursor: true,
            a11y: false,
            freeMode: true,
            speed: 9000,
            loop: true,
            slidesPerView: "auto",

            autoplay: {
                delay: 0.5,
                disableOnInteraction: false,
            },
            breakpoints: {
                0: {
                    spaceBetween: 30,
                },
                480: {
                    spaceBetween: 30,
                },
                768: {
                    spaceBetween: 40,
                },
                1024: {
                    spaceBetween: 100,
                }
            },
        },

        slideRuledGrid: {
            modules: [Pagination],

            spaceBetween: 30,
            slidesPerView: 1.5,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                dynamicBullets: true,
                clickable: true,
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.1,
                    spaceBetween: 10,
                },
                390: {
                    slidesPerView: 1.5,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 2.1,
                    spaceBetween: 30,
                },
                1024: {
                    slidesPerView: 3.1,
                    spaceBetween: 80,
                }
            },
        },
        slideReviewsGrid: {
            modules: [Pagination, Autoplay],

            spaceBetween: 30,
            slidesPerView: 1,
            autoHeight: true,

            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                dynamicBullets: true,
                clickable: true,
            },

            speed: 1200,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },

            breakpoints: {
                0: {
                    spaceBetween: 10,
                },
                390: {
                    spaceBetween: 10,
                },
                768: {
                    spaceBetween: 20,
                },
                1024: {
                    spaceBetween: 30,
                }
            },
        },
        slideCertificates: {
            modules: [Pagination, Autoplay],
            spaceBetween: 40,
            slidesPerView: 'auto',

            speed: 1200,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                dynamicBullets: true,
                clickable: true,
            },
            breakpoints: {
                0: {
                    spaceBetween: 20,
                },
                390: {
                    spaceBetween: 20,
                },
                768: {
                    spaceBetween: 20,
                },
                1024: {
                    spaceBetween: 40,
                }
            },
        }
    }

    const init = () => {
        slider();
        ruledGridHandle();
        reviewsGridHandle();
    };

    function slider() {
        const slideSelectors = document.querySelectorAll(selectors.slider);
        slideSelectors.forEach(function(itemSlider){
            var option = itemSlider.dataset.slide == "slideHero" ? options.slideHero 
                        : itemSlider.dataset.slide == "slideLogos" ? options.slideLogos 
                        : itemSlider.dataset.slide == "slideCertificates" ? options.slideCertificates 
                        : {};
            itemSlider.style.display = "";
            var sw = new Swiper(itemSlider, option);
            sw.disable();

            const observeSwiper = new IntersectionObserver((entries) => {
                entries.forEach((entry, index) => {
                    if (entry.isIntersecting){
                        sw.enable();
                        sw.update();
                        sw.autoplay && sw.autoplay.start();
                    }else{
                        sw.autoplay && sw.autoplay.pause();
                        sw.disable();
                    }
                })
            })

            observeSwiper.observe(itemSlider);
        });
    }

    function ruledGridHandle(){
        const rulesGrid = document.querySelectorAll('.ruled-grid');

        rulesGrid.forEach((rule) => {
            const dtSlide = rule.dataset.slide || "";
            const optSw = options[dtSlide];
            if (!optSw) return;

            if (window.innerWidth < 768){
                rule.className = "swiper-wrapper";
                for(let i = 0; i < rule.children.length; i++){
                    rule.children[i]?.classList.add('swiper-slide');
                    rule.children[i]?.classList.remove('group');
                }
                
                let parentRule = rule.parentElement;
                let swiper = document.createElement('div');
                swiper.classList.add('swiper');

                swiper.style.width = "calc(100% + var(--sidespace) )";
                swiper.style.maxWidth = "calc(100% + var(--sidespace) )";
                
                // let container = parentRule;
                // let i = 0;

                // while(!container.classList.contains('container') && i < 10){
                //     container = container.parentElement;
                //     i++;
                // }

                // container.classList.add('container-right');
                // container.classList.remove('container');
                
                let swpagination = document.createElement('div');
                swpagination.className = 'swiper-pagination swiper-pagination-right';

                swiper.appendChild(rule);
                swiper.appendChild(swpagination);
                parentRule.appendChild(swiper);

                new Swiper(swiper, optSw);
            }
        })
    }

    function reviewsGridHandle(){
        const reviewsGrid = document.querySelectorAll('.reviews-grid');

        reviewsGrid.forEach((review) => {
            const dtSlide = review.dataset.slide || "";
            const optSw = options[dtSlide];
            if (!optSw) return;

            if (window.innerWidth < 768){
                review.className = "swiper-wrapper";
                for(let i = 0; i < review.children.length; i++){
                    review.children[i]?.classList.add('swiper-slide');
                    review.children[i]?.classList.remove('group');
                }
                
                let parentReview = review.parentElement;
                let swiper = document.createElement('div');
                swiper.classList.add('swiper');
                
                let swpagination = document.createElement('div');
                swpagination.className = 'swiper-pagination';

                swiper.appendChild(review);
                swiper.appendChild(swpagination);
                parentReview.appendChild(swiper);

                new Swiper(swiper, optSw);
                setTimeout(function(){
                    swpagination.classList.add('swiper-pagination-right')
                }, 1000)
            }
        })
    }

    return {
        init: init
    }
}