export const ImagesHandler = function() {
    const cache = {};

    function lazyLoad(img) {
        const parameter = {
            width: img.width || img.naturalWidth || img.parentElement.clientWidth || "",
            height: img.height || img.naturalHeight || img.parentElement.clientHeight || ""
        };

        // img.loading = "lazy";
        img.setAttribute('width', parameter.width);
        img.setAttribute('height', parameter.height);
    
        if (img.alt == ""){
            const pathParts = img.src.split('/');
            const fileName = pathParts[pathParts.length - 1];
            img.alt = fileName;
        }

        Loaded(img);
    }

    const applyClassesAndReplace = (img, svgContent) => {
        svgContent.style.opacity = "0";
        img.style.display = 'none';

        const imgClasses = img.classList;
        imgClasses.forEach(cls => {
            svgContent.classList.add(cls);
        });

        img.insertAdjacentElement('afterend', svgContent);

        const setImgSize = () => {
            svgContent.style.width = img.style.width;
            svgContent.style.height = img.style.height;
            svgContent.setAttribute('width', svgContent.clientWidth || img.naturalWidth || img.parentElement.clientWidth || "");
            svgContent.setAttribute('height', svgContent.clientHeight || img.naturalHeight || img.parentElement.clientHeight || "");
            svgContent.style.opacity = "";
            img.remove();
        };

        setImgSize();
    };

    const loadSvg = (src) => {
        return new Promise((resolve, reject) => {
            if (cache[src]) {
                resolve(cache[src].cloneNode(true));
            } else {
                var xhr = new XMLHttpRequest();
                xhr.open('GET', src, true);
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                        let parser = new DOMParser();
                        let svgContent = parser.parseFromString(xhr.responseText, 'image/svg+xml').documentElement;
                        cache[src] = svgContent.cloneNode(true);
                        resolve(svgContent);
                    } else if (xhr.readyState === XMLHttpRequest.DONE) {
                        reject(`Failed to load SVG: ${xhr.status}`);
                    }
                };
                xhr.send();
            }
        });
    };
    async function handleSvg(img) {
        const src = img.dataset.lazysrc || img.src;
        try {
            const svgContent = await loadSvg(src);
            applyClassesAndReplace(img, svgContent);
        } catch (error) {
            console.error(error);
        }
    }

    function handleImg(img){
        img.src = img.dataset.lazysrc;
    }
    
    async function handle(img, callback){
        try{
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        callback(img);
                        observer.unobserve(entry.target);
                    }
                });
            });
            observer.observe(img);
        }catch(error){
            img.src = img.dataset.lazysrc;
            console.error('ERROR: ', error);
        }
    }

    function Loaded(img){
        if (img.classList.contains("svg")){
            handle(img, handleSvg);
        }else if (img.dataset.lazysrc){
            handle(img, handleImg);
        }
    }

    function init() {
        const lazyImages = document.querySelectorAll('img');
        lazyImages.forEach(function(img) {
            lazyLoad(img);
        });
    }

    return {
        init: init
    };
}
