export const BackToTop = function(){
    const init = () => {
        const backtotop = document.querySelectorAll('.jsbacktotop');
        backtotop.forEach(function(item){
            item.addEventListener('click', function(e){
                e.preventDefault();
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            })
        })
    }
    return {
        init: init
    }
}