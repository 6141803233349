import {InitPage} from "../Classes/InitPage";

export const PageHandler = function(){


    const options = {
        handler: normal
    }

    const init = () => {
        const jsPages = document.querySelectorAll('.js-pagination');
        jsPages.forEach((item) => {
            try{
                let callback = item.dataset.type || "handler";
                options[callback](item);
            }catch(error){
                console.error("ERROR: ", error);
            }
        })
    }

    function normal(item){
        const param = "paged";
        const paginationInit = new InitPage({
            param: param,
            selector: item.querySelector('.pagination'),
            items: item.querySelectorAll('.list>.group'),
            page: 8,
            scroll: {
                target: item,
                sticky: "header"
            },
        });

        window.addEventListener('popstate', function(e){
            let valueParam = e.state && e.state[param] ? e.state[param] : getQueryParam(param) ? parseInt(getQueryParam(param)) : 1;
            paginationInit.setCurrentPage(valueParam);
        })
        return paginationInit;
    }

    return {
        init: init
    }
}