import {updateURLParams, getQueryParam, isNonNegativeNumber} from "./Base"

export class InitPage {
    constructor(opts) {
        const pagination = class {
            constructor(opts) {
                this.options = Object.assign(pagination.defaults, opts);
                this.init();
            }

            init() {
                let plugin = this;
                let options = plugin.options;

                let pages = typeof(options.selector) === "object" ? options.selector : document.querySelector(`${options.selector}`);
                let totalItems = typeof(options.items) === "object" ? options.items : document.querySelectorAll(options.items);

                pages.innerHTML = "";
                let totalPage = Math.ceil(totalItems.length / options.page);
                
                let param = getQueryParam(options.param);
                let paramValue = 1;
                if (param){
                    paramValue = isNonNegativeNumber(param);
                    if (paramValue == -1 || paramValue < 1 || paramValue > totalPage){
                        paramValue = 1;
                    }
                }

                if (typeof options.callback == "function"){
                    options.callback(plugin);
                }

                plugin.results = {
                    items: totalItems,
                    currentPage: paramValue,
                    pages: pages,
                    totalPages: totalPage
                }

                if (!pages || !totalItems || totalPage <= 1) {
                    plugin.activeBox(-1);
                    pages.remove();
                    return false;
                }

                plugin.handleFeature();                
            }

            handleFeature(){
                this.paginationCreate();
            }

            getCurrentPage() {
                return this.results.currentPage;
            }

            setCurrentPage(number){
                let plugin = this;
                if (typeof number == "number" && number <= plugin.results.totalPages && number > 0){
                    plugin.results.currentPage = number;
                    plugin.paginationCreate();
                    
                    return plugin.results.currentPage;
                }
                return 0;
            }

            changePage(number){
                return this.setCurrentPage(number);
            }

            paginationCreate() {
                let plugin = this;
                let results = plugin.results;

                plugin.activeBox(results.currentPage);

                results.pages.innerHTML = plugin.createPagination(results.currentPage, results.totalPages);

                let strIcon = 	`<svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.54661 1L7.5332 7L1.54661 13" stroke="black" stroke-width="2"/>
                                </svg>
                                `;

                plugin.hiddenNextPrev();

                plugin.changeIcon(results.pages.querySelector('.next'), strIcon);
                plugin.changeIcon(results.pages.querySelector('.prev'), strIcon);

                // click button
                plugin.clickButton();
            }

            createPagination(page, totalPages) {
                let strStart = `<li class="prev"><a href="javascript:void(0)">prev</a></li>`;
                let strList = "";

                // khi tổng số page nhỏ hơn 5
                if (totalPages == 1) {
                    return "";
                } else if (totalPages < 5) {
                    for (let i = 1; i <= totalPages; i++) {
                        let strI = i < 10 ? `0${i}` : `${i}`;
                        if (i == page) {
                            strList += `<li class="is-actived" data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
                        } else {
                            strList += `<li data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
                        }
                    }
                } else if (
                    (page <= 3 || page >= totalPages - 2) &&
                    page > 0 &&
                    page <= totalPages
                ) {
                    // trường hợp active tạo thành 1 2 3 4 ... 20
                    if (page <= 3) {
                        if (page == 3 && totalPages == 5) {
                            for (let i = 1; i <= totalPages; i++) {
                                let strI = i < 10 ? `0${i}` : `${i}`;
                                if (i == page) {
                                    strList += `<li class="is-actived" data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
                                } else {
                                    strList += `<li data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
                                }
                            }
                        } else {
                            let visiblePage = page + 1;
                            if (page == 1) {
                                visiblePage = 3;
                            }
                            for (let i = 1; i <= visiblePage; i++) {
                                let strI = i < 10 ? `0${i}` : `${i}`;

                                if (i == page) {
                                    strList += `<li class="is-actived" data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
                                } else {
                                    strList += `<li data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
                                }
                            }
                            let strTotal = totalPages < 10 ? `0${totalPages}` : `${totalPages}`;
                            strList += `<li class="dot">...</li>`;
                            strList += `<li data-number="${totalPages}"><a href="javascript:void(0)">${strTotal}</a></li>`;
                        }
                    }
                    // trường hợp active tạo thành 1 ... 18 19 20
                    else {
                        strList += `<li data-number="1"><a href="javascript:void(0)">01</a></li>`;
                        strList += `<li class="dot">...</li>`;

                        let visiblePage2 = page - 1;
                        if (page == totalPages) {
                            visiblePage2 = totalPages - 2;
                        }

                        for (let i = visiblePage2; i <= totalPages; i++) {
                            let strI = i < 10 ? `0${i}` : `${i}`;

                            if (i == page) {
                                strList += `<li class="is-actived" data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
                            } else {
                                strList += `<li data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
                            }
                        }
                    }
                }
                // trường hợp active tạo thành 1 ... 7 8 9 ... 20
                else if (page > 3 && page < totalPages - 2) {
                    strList += `<li data-number="1"><a href="javascript:void(0)">01</a></li>`;
                    strList += `<li class="dot">...</li>`;
                    for (let i = page - 1; i <= page + 1; i++) {
                        let strI = i < 10 ? `0${i}` : `${i}`;

                        if (i == page) {
                            strList += `<li class="is-actived" data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
                        } else {
                            strList += `<li data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
                        }
                    }
                    let strTotal = totalPages < 10 ? `0${totalPages}` : `${totalPages}`;
                    strList += `<li class="dot">...</li>`;
                    strList += `<li data-number="${totalPages}"><a href="javascript:void(0)">${strTotal}</a></li>`;
                } else {
                    console.log("error pagination!!!");
                    return "";
                }

                let strEnd = `<li class="next"><a href="javascript:void(0)">next</a></li>`;

                return `<ul>${strStart.concat(strList, strEnd)}</ul>`;
            }

            activeBox(currentActive) {
                const results = this.results;
                const options = this.options;
                results.items.forEach(function (item) {
                    item.style.display = "none";
                });

                results.items.forEach(function (item, index) {
                    if (currentActive == -1){
                        item.style.display = "";
                        return;
                    }

                    let itemPage = Math.ceil((index + 1) / options.page);
                    if (itemPage !== currentActive) {
                        item.style.display = "none";
                    }else{
                        item.style.display = "";
                    }
                });
            }

            clickButton() {
                let plugin = this;
                let liBtns = plugin.results.pages.getElementsByTagName("li");

                for (let i = 0; i < liBtns.length; i++) {
                    let item = liBtns[i];

                    item.onclick = function () {
                        let dataItem = parseInt(this.getAttribute("data-number"));

                        if (this.classList.contains("btn-hidden") || dataItem == plugin.results.currentPage) {
                            return;
                        }

                        if ( this.classList.contains("next") && plugin.results.currentPage < plugin.results.totalPages) {
                            // Click Next button
                            plugin.setCurrentPage(++plugin.results.currentPage);
                        } else if (this.classList.contains("prev") && plugin.results.currentPage > 1) {
                            // Click Prev button
                            plugin.setCurrentPage(--plugin.results.currentPage);
                        } else {
                            if (dataItem < 0 || dataItem > plugin.results.totalPages) {
                                return false;
                            }
                            plugin.setCurrentPage(dataItem);
                        }

                        
                        // scroll 
                        if (plugin.options.scroll && plugin.options.scroll.target) {
                            plugin.scrollToTop();
                        }
                        
                        if (typeof plugin.params == "object"){
                            plugin.params[plugin.options.param] = plugin.results.currentPage;
                        }else{
                            plugin.params = {
                                [plugin.options.param]: plugin.results.currentPage
                            }
                        }

                        let params = plugin.params;
                        if (params){
                            updateURLParams(params);
                        }
                    };
                }
            }

            scrollToTop() {
                let scroll = this.options.scroll;
                
                let topFeatured = 0;
                let featuredItem = typeof scroll.target == "string" ? document.querySelector(scroll.target) : scroll.target;

                if (featuredItem) {
                    let parentFeatured = featuredItem.offsetParent;
                    let count = 0;
                    while (parentFeatured.nodeName !== "BODY" && count < 100) {
                        topFeatured += parentFeatured.offsetTop;
                        parentFeatured = parentFeatured.offsetParent;
                        count++;
                    }
                    topFeatured += featuredItem.offsetTop;
                }

                if (scroll.sticky){
                    let sticky = typeof scroll.sticky == "string" ? document.querySelector(scroll.sticky) : scroll.sticky;
                    topFeatured -= sticky.clientHeight;
                }

                window.scrollTo({
                    top: topFeatured,
                    left: 0,
                    behavior: "smooth",
                });
            }

            changeIcon(
                objClass,
                strIcon
            ) {
                objClass.innerHTML = strIcon;
            }

            hiddenNextPrev() {
                const totalPage = this.results.totalPages;
                const itemPrev = this.results.pages.querySelector('.prev');
                const itemNext = this.results.pages.querySelector('.next');
                if (itemPrev && itemNext && totalPage > 0){
                    if (totalPage === 1) {
                        itemPrev.classList.add("btn-hidden");
                        itemNext.classList.add("btn-hidden");
                    } else if (this.results.currentPage === 1) {
                        itemPrev.classList.add("btn-hidden");
                        itemNext.classList.remove("btn-hidden");
                    } else if (this.results.currentPage === totalPage) {
                        itemPrev.classList.remove("btn-hidden");
                        itemNext.classList.add("btn-hidden");
                    } else {
                        itemPrev.classList.remove("btn-hidden");
                        itemNext.classList.remove("btn-hidden");
                    }
                }
            }

            destroy(){
                try{

                    return true;
                }catch(error){
                    return error;
                }
            }
        }

        pagination.defaults = {
            param: "paged",
            selector: "", // Class pagination
            items: "", // Elements
            page: 0,
            scroll: {
                target: "", // target scroll
                sticky: "" // sticky
            },
            callback: function(){
                return {
                    type: "clicked", // or "popstate"
                };
            }
        };

        this.pagination = new pagination(opts);
    }

    getCurrentPage(){
        return this.pagination.getCurrentPage();
    }
    setCurrentPage(number){
        return this.pagination.changePage(number);
    }

    destroy(){
        return this.pagination.destroy();
    }
}
