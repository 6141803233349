// Generals
import { Slider } from "./modules/Slider";
import { BackToTop } from "./modules/BackToTop";

import { ClickedHandler } from "./Handler/ClickedHandler";
import { AriaHandler } from "./Handler/AriaHandler";
import { ImagesHandler } from "./Handler/ImagesHandler";
import { PageHandler } from "./Handler/PageHandler";
import { CopyToClipboardHandler } from "./Handler/CopyToClipboardHandler";

const BraneyModulesManager = {
    generals: {
        ClickedHandler: ClickedHandler,
        AriaHandler: AriaHandler,
        ImagesHandler: ImagesHandler,
        PageHandler: PageHandler,
        CopyToClipboardHandler: CopyToClipboardHandler,
        
        Slider: Slider,
        BackToTop: BackToTop,
    },
    
    // modules: {
        
    // },

    runModule: function() {
        // General init
        for (let general in this.generals) {
            if (this.generals.hasOwnProperty(general)) {
                this.generals[general]().init();
            }
        }

        // Module init
        // if (this.modules[moduleName]){
        //     const module = this.modules[moduleName]();
        //     module.init();
        // }
    }
};
BraneyModulesManager.runModule();

// window.addEventListener('DOMContentLoaded', function(){
// })

// registerServiceWorker();
// const registerServiceWorker = async () => {
//     if ("serviceWorker" in navigator) {
//       try {
//         const registration = await navigator.serviceWorker.register("/sw.js", {
//           scope: "/",
//         });
//         if (registration.installing) {
//           console.log("Service worker installing");
//         } else if (registration.waiting) {
//           console.log("Service worker installed");
//         } else if (registration.active) {
//           console.log("Service worker active");
//         }
//       } catch (error) {
//         console.error(`Registration failed with ${error}`);
//       }
//     }
// };

