export const CopyToClipboardHandler = function (){
    const selectors = {
        copyText: '.jsCopyClipboard'
    }
    const init = function(){
        document.querySelectorAll(selectors.copyText).forEach(function(item){
            item.onclick = function(e) {
                e.preventDefault();
                copyToClipboard(item, handle);
            };
        })
    }

    function setTooltip(selector, message){
        selector.classList.add('tooltip');
        selector.setAttribute('title', message);
        selector.style.pointerEvents = "none";
    
        let innerText = selector.innerHTML;
        selector.innerHTML = `<svg height="1em" viewBox="0 0 448 512"><path fill="#F11B23" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>`;
        
        setTimeout(function() {
            selector.innerHTML = innerText;        
            selector.removeAttribute('title');
            selector.classList.remove('tooltip');
            selector.style.pointerEvents = "";
        }, 1000);
    }

    function handle(status = "", selector = null){
        if (status == "success" && selector){
            setTooltip(selector, "Copied!");
        }
    }

    const copyToClipboard = function(selector, callback = function(){}) {
        try{
            const copyText = selector.href;
			const input = document.createElement("input");
			input.value = copyText;

			input.setAttribute('type', 'text');
			input.style.height = "0";
			input.style.opacity = "0";

			let body = document.querySelector('body');
			body.appendChild(input);
			input.select();

			document.execCommand("copy");
			input.remove();
            console.log(selector)

			callback('success', selector);
		}
		catch (error) {
            console.error("ERROR CopyToClipboardJs: ", error);
		}

    }

    return {
        init: init
    }
}