export const AriaHandler = function() {
    const init = function(){
        handleModal();
        handleChecked();
    }
    function handleModal(){
        const ariaModal = document.querySelectorAll('.js-aria-modal');
        ariaModal.forEach((item) => {
            let jsOpened = item.querySelector('.jsopened');
            let jsClosed = item.querySelectorAll('.jsclosed');
            if (!jsOpened){
                return;
            }
            let modal = item.querySelector('[aria-modal]');
            modal.style.display = "none";

            jsOpened.addEventListener('click', function(e){
                e.preventDefault();
                modal.style.display = "";
            })
            
            jsClosed.forEach((close) => {
                close.addEventListener('click', function(e){
                    e.preventDefault();
                    modal.style.display = "none";
                })
            })
        })
    }
    function handleChecked(){
        const ariaSelected = document.querySelectorAll('.js-aria-selected');
        ariaSelected.forEach((item) => {
            let selectedGroup = item.querySelectorAll('[aria-selected]');
            selectedGroup.forEach((selItem) =>{
                selItem.addEventListener('click', function(e){
                    const isSelected = this.getAttribute('aria-selected') === 'true';
                    this.setAttribute('aria-selected', !isSelected);
                })
            })
        }) 
    }
    return {
        init: init
    }
}