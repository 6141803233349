// export const cp = 1024; // computer min 1024
// export const tablet = 768; // table min 768

export const delHandle = function (list, str, idxActived = -1) {
	if (typeof str == "string") {
		list.forEach(function (item, idx) {
			if (idx !== idxActived){
				item.classList.remove(str);
			}
		});
	}
};

export const isNonNegativeNumber = function(value){
	// Sử dụng parseInt để chuyển đổi giá trị thành số nguyên
	let numberValue = parseInt(value, 10);

	// Kiểm tra xem giá trị đã được chuyển đổi thành số thành công
	if (!isNaN(numberValue) && numberValue >= 0) {
		return numberValue;
	}
	return -1;
}

export const handeleClickGroup = function(group, idxActived, callBack = function(){}){
	let items;
	group.forEach(function(gpItem, idxGp){
		if(idxGp === idxActived){
			gpItem.classList.add('is-showed');
			gpItem.style.display = "";
			// gpItem.style.opacity = "";
			// gpItem.style.visibility = "";
			// gpItem.style.transform = "";
			gpItem.style.animation = ".8s isShowed cubic-bezier(0.075, 0.82, 0.165, 1) forwards";

			items = gpItem;
		}else{
			gpItem.classList.remove('is-showed');
			gpItem.style.display = "none";
			gpItem.style.opacity = "0";
			gpItem.style.visibility = "hidden";
			gpItem.style.transform = "translateY(100px)";

			gpItem.style.animation = "";
		}
	})

	callBack(items);
}

export const handleHiddenItem = function(item, callBack = function(){}){
	item.classList.remove('is-showed');
	item.style.display = "none";
	item.style.opacity = "0";
	item.style.visibility = "hidden";
	item.style.transform = "translateY(100px)";
	item.style.animation = "";

	callBack(item);
}


export const handleVisiblleItem = function(item, callBack = function(){}){
	item.classList.add('is-showed');
	item.style.display = "";
	item.style.animation = ".8s isShowed cubic-bezier(0.075, 0.82, 0.165, 1) forwards";
	callBack(item);
}

export const getQueryParam = function(name) {
	let searchParams = new URLSearchParams(window.location.search);
	return searchParams.get(name);
}

export const updateURLParams = function(paramsToUpdate) {
	let searchParams = new URLSearchParams(window.location.search);
	for (let key in paramsToUpdate) {
		if (paramsToUpdate.hasOwnProperty(key)) {
			searchParams.set(key, paramsToUpdate[key]);
		}
	}
	let newURL = window.location.pathname + '?' + searchParams.toString();
	window.history.pushState({ ...paramsToUpdate }, '', newURL);
}

export const removeQueryParam = function(paramName) {
	let searchParams = new URLSearchParams(window.location.search);

	searchParams.delete(paramName);
	let newURL;
	if (searchParams.toString() !== '') {
		newURL = window.location.pathname + '?' + searchParams.toString();
	} else {
		newURL = window.location.pathname;
	}

	history.replaceState({}, '', newURL);
}


export const popupHandle = function(target, callBackClose = function(){return""}){
	try{
		if (typeof target === 'string'){
			target = document.querySelector(`${target}`);
		}

		function closeHandle(popup, callBack = function(){}){
			popup.classList.remove('is-showed');

			callBack(popup);
		}

		function clickHandleTarget(e){
			e.preventDefault();
			popup.classList.add('is-showed');
		}
		
		target.removeEventListener('click', clickHandleTarget);
		target.addEventListener('click', clickHandleTarget);

		let popup = document.querySelector(`[data-popup-section="${target.getAttribute('data-popup')}"]`) || document.getElementById(`${target.getAttribute('data-popup')}`);
		if (!popup){
			throw `Not found popup: ${target.getAttribute('data-popup')}`;
		}

		if (popup.classList.contains('initialized')){
			return;
		}

		let closes = popup.querySelectorAll('.close');

		function evtClose(e){
			e.preventDefault();
			closeHandle(popup, callBackClose);
		}

		closes.forEach(function(close){
			close.addEventListener('click', evtClose);
		})
		
		let cancels = popup.querySelectorAll('.cancel');
		cancels.forEach(function(cancel){
			cancel.addEventListener('click', function(e){
				e.preventDefault();
				closeHandle(popup);
			})
		})

		popup.addEventListener('click', function(e){
			e.stopPropagation();
			if (e.target === popup){
				closeHandle(popup, callBackClose);
			}
		})

		popup.classList.add("initialized");
	}
	catch(err){
		console.error(err);
	}
}


// export const stepNumber = function (finalNumber, duration = 2000, start = 0, callback){
// 	const startTime = performance.now() + start;

// 	function step(currentTime) {
// 		const elapsedTime = currentTime - startTime;

// 		if (elapsedTime > duration) {
// 		  callback(finalNumber);
// 		} else {
// 		  const rate = elapsedTime / duration
// 		  const currentNumber = Math.round(rate * finalNumber)
// 		  callback(currentNumber)
// 		  requestAnimationFrame(step)
// 		}
// 	}

// 	requestAnimationFrame(step);
// }