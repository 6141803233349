export const ClickedHandler = function() {
    const init = function(){
        const jsClicks = document.querySelectorAll('.jsClicked');
        jsClicks.forEach(function(item){
            let link = item.querySelector('a');
            if (!link) return;
            item.style.cursor = "pointer";
            item.addEventListener('click', function(e){
                e.preventDefault();
                window.location.href = link.href;
            })
        })
    }
    return {
        init: init
    }
}